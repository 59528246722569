<template>
  <div>
    <div class="container">
      <SubHeader :pathList="pathList" :title="title" />

      <div id="contents" class="contents">
        <div class="title-sort-box">
          <h3>{{ $t('subMenu.qna') }}</h3>
          <div class="sort-side">
            <select class="isf term-sel" v-model="searchCondition">
              <option value="">{{ $t('qna.search-condition1') }}</option>
              <option value="questSj">{{ $t('qna.search-condition2') }}</option>
              <option value="questCn">{{ $t('qna.search-condition3') }}</option>
              <option value="qstnerNm">{{
                $t('qna.search-condition4')
              }}</option>
            </select>
            <span class="search-icon-wrap">
              <input
                type="text"
                class="isf"
                v-model="searchData"
                maxlength="20"
                dataType="LITERAL"
                v-on:keyup.enter="onSearch"
              />
              <button class="ic-search" @click="onSearch">
                {{ $t('common.btn-search') }}
              </button>
            </span>
            <button type="button" class="bt-md bt-default" @click="goQnaInsert">
              <span>{{ $t('qna.btn-register') }}</span>
            </button>
          </div>
        </div>
        <!--/title-sort-box-->
        <list-form
          :fetchApi="fetchQnaList"
          :columns="columns"
          @detail="checkQnaIsSealed"
          :page="page"
          ref="qnaList"
        />
      </div>
    </div>
    <password-check-popup
      v-if="pwdCheckPopup"
      @close="pwdCheckPopup = false"
      :fetchApi="checkPassword"
      :selectedRow="selectedRow"
      :page="page"
      @next="goQnaDetail"
      category="qna"
    />
  </div>
</template>
<script>
import ListForm from '@/components/common/ListForm';
import CommonMixins from '@/mixins/CommonMixins.js';
import { fetchQnaList, checkPassword } from '@/api/qnaApi.js';
import PasswordCheckPopup from '@/components/common/PasswordCheckPopup.vue';

export default {
  components: { ListForm, PasswordCheckPopup },
  data() {
    return {
      pathList: [
        this.$i18n.t('menu.home'),
        this.$i18n.t('menu.support'),
        this.$i18n.t('subMenu.qna'),
      ],
      title: {
        mainTitle: this.$i18n.t('menu.support'),
        smallTitle: this.$i18n.t('menu.support-eng'),
      },
      columns: [
        {
          label: this.$i18n.t('qna.table-column1'),
          field: 'questSj',
          width: '61%',
        },
        {
          label: this.$i18n.t('qna.table-column2'),
          field: 'qstnerNm',
          formatFn: val => CommonMixins.methods.common_getMaskingName(val),
          width: '14%',
        },
        {
          label: this.$i18n.t('qna.table-column3'),
          field: 'questDt',
          formatFn: val => CommonMixins.methods.common_getDateString2(val),
          width: '14%',
        },
        {
          label: this.$i18n.t('qna.table-column4'),
          field: 'unRspns',
          sortable: false,
          formatFn: val => this.common_getReplay(val),
          width: '8%',
        },
      ],
      searchCondition: '',
      searchData: '',
      selectedRow: '',
      pwdCheckPopup: false,
      page: this.$route?.params?.page || 1,
    };
  },

  methods: {
    // 문의사항 목록
    fetchQnaList(params) {
      return fetchQnaList(params);
    },
    // 검색
    onSearch() {
      let params = {
        page: 1,
        srchCondition: this.searchCondition,
        srchData: this.searchData,
      };
      this.$refs.qnaList.onSearch(params);
    },
    // 비밀번호 여부 확인
    checkQnaIsSealed({ isSealed, questNo, page }) {
      this.selectedRow = questNo;
      this.page = page;
      if (isSealed === 'Y') {
        this.pwdCheckPopup = true;
      } else {
        this.goQnaDetail(questNo, page);
      }
    },
    // 비밀번호 체크
    checkPassword(params) {
      return checkPassword(params);
    },
    // 문의 상세 페이지로 이동
    goQnaDetail(questNo, page) {
      this.pwdCheckPopup = false;
      this.$router.push({ name: 'qnaDetail', params: { questNo, page } });
    },
    // 문의 등록 페이지로 이동
    goQnaInsert() {
      this.$router.push(`/qnaInsert`);
    },
    common_getReplay(cellValue) {
      var retVal = '';
      if (cellValue == 'N') {
        retVal = this.$i18n.t('qna.answered-flag');
      }
      return retVal;
    },
  },
};
</script>
<style></style>
