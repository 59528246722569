<template lang="">
  <div class="pop-overlay active">
    <div class="popup-area mini active">
      <div class="pop-header">
        <h1 class="pop-title">{{ $t('common.checkPop-title') }}</h1>
        <button class="pop-x alert_close_btn" @click="$emit('close')">
          Close
        </button>
      </div>
      <div class="pop-contents">
        <div class="pop-password">
          <!-- <p v-if="category === 'qna'">
            Please enter <label class="star"></label><b>password</b>.
          </p> -->
          <p
            v-if="category === 'qna'"
            v-html="$t('common.checkPop-qna-content')"
          ></p>
          <p v-else v-html="$t('common.checkPop-content')"></p>
          <span
            ><i class="ic-lock"></i
            ><input
              type="password"
              class="isf"
              v-model.trim="password"
              maxlength="10"
              v-on:keyup.enter="checkPassword"
          /></span>
        </div>
        <div class="btns-bottom">
          <button
            type="button"
            @click="$emit('close')"
            class="bt-md bt-default alert_close_btn"
          >
            {{ $t('common.btn-cancel') }}
          </button>
          <button type="button" class="bt-md bt-red" @click="checkPassword">
            {{ $t('common.btn-ok') }}
          </button>
        </div>
      </div>
      <!--/pop-contents-->
    </div>
  </div>
</template>
<script>
import sha256 from 'sha256';
export default {
  props: {
    category: {
      type: String,
    },
    selectedRow: {
      type: Number,
    },
    fetchApi: {
      type: Function,
    },
    page: {
      type: Number,
    },
  },
  data() {
    return {
      password: '',
    };
  },
  methods: {
    // 비밀번호 체크
    async checkPassword() {
      if (this.password == '') {
        this.$dialogs.alert(this.$i18n.t('common.checkPop-empty-password'));
        return;
      }
      let parmas = {};
      if (this.category === 'qna') {
        parmas = {
          questNo: this.selectedRow,
          password: this.password,
        };
      } else {
        parmas = {
          userId: sessionStorage.getItem('userId'),
          password: sha256(this.password),
        };
      }
      const {
        data: { valid, message },
      } = await this.fetchApi(parmas);
      if (valid) {
        this.$emit('next', this.selectedRow, this.page);
      } else {
        this.$dialogs.alert(this.jsonEscape(message));
        this.password = '';
      }
    },
    jsonEscape(string) {
      var result = string
        ? string.replace('\\n', '<br>')
        : this.$i18n.t('common.checkPop-wrong-password');
      return result;
    },
  },
};
</script>
<style lang=""></style>
